














































































































































































































































































































































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.v-text-field {
  display: flex;
  width: 50px;
}

.showValuesDetailsButtom {
  margin-left: 16px;
  font-size: 20px;

  &:hover {
    color: $colorPrimary;
  }
}
